import { Authority } from 'ufinet-web-functions'

export const getPermissionsByPathname = (pathname: string, roles: any) => {
	switch (pathname) {
		case 'bitacora':
			return Authority.getBitacoraPermissions(roles)

		case 'conexiones':
			return Authority.getConexionesPermissions(roles)

		case 'cto':
			return Authority.getCTOPermissions(roles)

		case 'CtoA':
			return Authority.getCTOAPermissions(roles)

		case 'CtoB':
			return Authority.getCTOBPermissions(roles)

		case 'CtoCoverage':
			return Authority.getCTOCoveragePermissions(roles)

		case 'Ctoport':
			return Authority.getCTOPortPermissions(roles)

		case 'CtoPortFixed':
			return Authority.getCTOPortFixedPermissions(roles)

		case 'CtoModel':
			return Authority.getCTOModelPermissions(roles)

		case 'GponTree':
			return Authority.getGponTreePermissions(roles)

		case 'Operator':
			return Authority.getOperatorPermissions(roles)

		case 'pfs':
			return Authority.getPFSPermissions(roles)

		case 'PfsModel':
			return Authority.getPFSModelPermissions(roles)

		case 'PfsPortIn':
			return Authority.getPFSPortInPermissions(roles)

		case 'PfsPortOut':
			return Authority.getPFSPortOutPermissions(roles)

		case 'PhysicalNetwork':
			return Authority.getPhysicalNetworkPermissions(roles)

		case 'polygon':
			return Authority.getPolygonPermissions(roles)

		case 'PolygonCoverage':
			return Authority.getPolygonCoveragePermissions(roles)

		case 'PolygonOptimization':
			return Authority.getPolygonOptimizationPermissions(roles)

		case 'ResourceCharacteristic':
			return Authority.getResourcesCharacteristicsPermissions(roles)

		case 'ResourceRelationship':
			return Authority.getResourceRelationshipPermissions(roles)

		case 'Resources':
			return Authority.getResourcesPermissions(roles)

		case 'Services':
			return Authority.getServicesFtthPermissions(roles)

		case 'SplitterPFP':
			return Authority.getSplitterPfpPermissions(roles)

		case 'SplitterPFS':
			return Authority.getSplitterPfsPermissions(roles)

		case 'VlanInventory':
			return Authority.getVlanInventoryPermissions(roles)

		case 'OLTRegistry':
			return Authority.getOltRegistryPermissions(roles)

		default:
			return Authority.isEmptyPermissions
	}
}
