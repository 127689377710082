import { FC, useCallback, useEffect } from 'react'
import { UfinetActionButton, UfinetInput, UfinetSelect, IUfinetSelectOption } from 'ufinet-web-components'
import { onFormikTextChanges } from 'ufinet-web-functions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import {
	editRow,
	addRow,
	getOperatorOptions,
	getStatusOptions,
	getPhysicalNetworkForOperatorOptions,
	getCtoModelOptions,
} from 'api/requests'
import { Translation } from 'utils/translation/Translation'

type NewAndEditCtoModalProps = {
	hideModal: Function
	apiRoutePath: string
	row?: any
	successFn: Function
	inputs?: any
	toInvalidateQuery: string
	onSuccessAddMessage: string
	onSuccessEditMessage: string
	onErrorGenericMessage: string
}

const NewAndEditModal: FC<NewAndEditCtoModalProps> = ({
	hideModal,
	apiRoutePath,
	row,
	successFn,
	inputs,
	toInvalidateQuery,
	onSuccessAddMessage,
	onSuccessEditMessage,
	onErrorGenericMessage,
}) => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: (values: any) =>
			Object.keys(row).length ? editRow(values, apiRoutePath) : addRow(values, apiRoutePath),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [toInvalidateQuery] })
			hideModal()
			successFn && successFn()
			row ? toast.success(onSuccessEditMessage) : toast.success(onSuccessAddMessage)
		},
		onError: (error: any) => {
			toast.error(
				error?.response?.data?.message ||
					error?.response?.data?.detail ||
					error?.response?.data?.title ||
					onErrorGenericMessage
			)
		},
	})

	const operatorOptionsQuery = useQuery({
		queryKey: [`${apiRoutePath}/filterStatus`],
		queryFn: () => getOperatorOptions(),
		enabled: inputs.some((input: any) => input.selectableOptions === 'operatorCode'),
	})
	const { data: operatorOptions } = operatorOptionsQuery

	const physicalNetworkForOperatorQuery = useQuery({
		queryKey: [`${apiRoutePath}/physicalNetworkForOperatorOptions`],
		queryFn: () => getPhysicalNetworkForOperatorOptions(),
		enabled: inputs.some((input: any) => input.selectableOptions === 'physicalNetworkForOperator'),
	})

	const { data: physicalNetworkForOperatorOptions } = physicalNetworkForOperatorQuery

	// Do query only when inputs has "status" column
	const statusOptionsQuery = useQuery({
		queryKey: ['statusOptions'],
		queryFn: () => getStatusOptions(`${apiRoutePath}/filterStatus`),
		// enabled when inputs has "status" column and apiRoutePath changes
		enabled: inputs.some((input: any) => input.selectableOptions === 'status'),
		// Refetch when when component mounts
		refetchOnMount: true,
	})
	const { data: statusOptions } = statusOptionsQuery

	const ctoModelOptionsQuery = useQuery({
		queryKey: ['ctoModelOptions'],
		queryFn: () => getCtoModelOptions(`${apiRoutePath}/filterModel`),
		enabled: inputs.some((input: any) => input.selectableOptions === 'ctoModel'),
		refetchOnMount: true,
	})
	const { data: ctoModelOptions } = ctoModelOptionsQuery

	const polygonTypeOptions = [
		{ label: 'SDU', value: 'SDU' },
		{ label: 'MDU', value: 'MDU' },
	]

	useEffect(() => {
		return () => {
			formik.resetForm()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { mutateAsync: mutate, isPending } = mutation

	// Function to generate the Yup validation schema
	const generateColumnsSchema = (columns: any) => {
		const schemaShape: any = {}
		columns.forEach((column: any) => {
			if (column.columnType === 'string' && column.isFieldRequired) {
				schemaShape[column.columnName] = Yup.string()
					.max(column.maxLength || Infinity, column.maxLengthErrorText ? Translation(column.maxLengthErrorText) : '')
					.required(Translation(column.requiredFieldErrorText))
			}
			if (column.columnType === 'number' && column.isFieldRequired) {
				schemaShape[column.columnName] = Yup.number().required(Translation(column.requiredFieldErrorText))
			}
		})

		return Yup.object().shape(schemaShape)
	}

	// Function to generate the Formik initial values
	const generateInitialValues = (columns: any, cto: string) => {
		const schemaShape: any = {}
		columns.forEach((column: any, index: number) => {
			schemaShape[column.columnName] = cto[column.columnName] || ''
		})

		return schemaShape
	}

	const dataFormSchema = generateColumnsSchema(inputs)

	const formik = useFormik({
		initialValues: generateInitialValues(inputs, row),
		validationSchema: dataFormSchema,
		onSubmit: (values) => (row ? mutate(values) : mutate(values)),
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onTextChange = useCallback(onFormikTextChanges, [])

	type MyFormikErrors = {
		[key in typeof inputs[number]['columnName']]?: string
	}

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row pb-10">
				{inputs.map((column: any, index: number) =>
					column.selectableOptions === 'operatorCode' ? (
						<UfinetSelect
							labelTitle={Translation(column.columnLabelIntl)}
							className="col-4"
							options={operatorOptions}
							requiredIcon={column.isFieldRequired}
							error={(formik.errors as MyFormikErrors)[column.columnName]}
							onChange={(value: any) => {
								formik.setFieldValue(column.columnName, value.value)
							}}
							value={
								{
									label: formik.values?.operator_Code || formik.values?.operatorCode || '',
									value: formik.values?.operator_Code || formik.values?.operatorCode || '',
								} as IUfinetSelectOption
							}
						/>
					) : column.selectableOptions === 'status' ? (
						<UfinetSelect
							labelTitle={Translation(column.columnLabelIntl)}
							className="col-4"
							options={statusOptions}
							requiredIcon={column.isFieldRequired}
							error={(formik.errors as MyFormikErrors)[column.columnName]}
							onChange={(value: any) => {
								formik.setFieldValue(column.columnName, value.value)
							}}
							value={
								{
									label: formik.values.status,
									value: formik.values.status,
								} as IUfinetSelectOption
							}
						/>
					) : column.selectableOptions === 'polygonType' ? (
						<UfinetSelect
							labelTitle={Translation(column.columnLabelIntl)}
							className="col-4"
							options={polygonTypeOptions}
							requiredIcon={column.isFieldRequired}
							error={(formik.errors as MyFormikErrors)[column.columnName]}
							onChange={(value: any) => {
								formik.setFieldValue(column.columnName, value.value)
							}}
							value={
								{
									label: formik.values.type,
									value: formik.values.type,
								} as IUfinetSelectOption
							}
						/>
					) : column.selectableOptions === 'physicalNetworkForOperator' ? (
						<UfinetSelect
							labelTitle={Translation(column.columnLabelIntl)}
							className="col-4"
							options={physicalNetworkForOperatorOptions}
							requiredIcon={column.isFieldRequired}
							error={(formik.errors as MyFormikErrors)[column.columnName]}
							onChange={(value: any) => {
								formik.setFieldValue(column.columnName, value.value)
							}}
							value={
								{
									label: formik.values.physicalNetwork,
									value: formik.values.physicalNetwork,
								} as IUfinetSelectOption
							}
						/>
					) : column.selectableOptions === 'ctoModel' ? (
						<UfinetSelect
							labelTitle={Translation(column.columnLabelIntl)}
							className="col-4"
							options={ctoModelOptions}
							requiredIcon={column.isFieldRequired}
							error={(formik.errors as MyFormikErrors)[column.columnName]}
							onChange={(value: any) => {
								console.log({ value, column })

								console.log({ value: formik.values })
								formik.setFieldValue(column.columnName, value.value)
							}}
							value={
								{
									label: formik.values.ctO_MODEL,
									value: formik.values.ctO_MODEL,
								} as IUfinetSelectOption
							}
						/>
					) : column.dontShowInTableAndModal ? (
						<></>
					) : (
						<UfinetInput
							key={column.columnName}
							requiredIcon={column.isFieldRequired}
							error={(formik.errors as MyFormikErrors)[column.columnName]}
							type={column.columnType === 'string' ? 'text' : 'number'}
							labelTitle={Translation(column.columnLabelIntl)}
							className="col-4"
							isDisabled={
								(index === 0 && Object.keys(row).length !== 0) ||
								(column.isFieldEditDisabled && Object.keys(row).length !== 0)
							}
							onChange={onTextChange(formik, column.columnName)}
							value={formik.values[column.columnName as keyof typeof formik.values] || ''}
						/>
					)
				)}
			</div>
			<UfinetActionButton isDisabled={isPending} className="mt-10" content={Translation('SAVE')} />
		</form>
	)
}

export default NewAndEditModal
